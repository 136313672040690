"use client";

import {EmailControl, EmailControlMethods} from "@/components/common/EmailControl";
import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState, MouseEvent, useMemo} from "react";
import {PasswordControl, PasswordControlMethods} from "@/components/common/PasswordControl";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Anchor, Checkbox, Group, Stack, Text, useMantineTheme} from "@mantine/core";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {PhotoSensitivityWarning} from "@/components/common/PhotoSensitivityWarning";
import {IconExclamationCircle} from "@tabler/icons-react";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {APP_URL, IS_ALPHA, IS_BETA, IS_PAID_IN_PRODUCTION, SUPPORT_EMAIL} from "@/common/utils";
import {Accent} from "@/components/common/Accent";
import {Span} from "@/components/common/Span";
import {Emp} from "@/components/common/Emp";
import * as Sentry from "@sentry/nextjs";
import {useKeyEvents} from "@/components/common/hooks/useKeyEvents";

export const SignUpView = forwardRef((
    {
        viewLink,
        email: signUpEmail,
        affiliate
    }: AuthViewProps, ref: Ref<AuthViewApi>
) => {
    // console.log('SignUpView::affiliate', affiliate);

    const { gaSignup } = useKeyEvents();

    const [email, setEmail] = useState(signUpEmail ?? '');
    const emailApi = useRef<EmailControlMethods | null>(null);
    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const [password, setPassword] = useState('');
    const passApi = useRef<PasswordControlMethods | null>(null);
    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const [confPassword, setConfPassword] = useState('');
    const confPassApi = useRef<PasswordControlMethods | null>(null);
    const onConfPasswordChange = useCallback((value: string) => {
        setConfPassword(value);
    }, []);

    const [agreed, setAgreed] = useState(false);
    const [consented, setConsented] = useState(false);

    const [showSeizureWarning, setShowSeizureWarning] = useState(false);
    const onShowSeizureWarning = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowSeizureWarning(true);
        return false;
    }, []);
    const onSeizureWarningClosed = useCallback(() => {
        setShowSeizureWarning(false);
    }, []);

    const termsError = useMemo(() => {
        return agreed ? null : 'You must agree to the terms of service and privacy policy';
    }, [agreed]);
    const [showTermsError, setShowTermsError] = useState(false);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        if (full && termsError != null) {
            setShowTermsError(true);
        }
        const errors = [
            emailApi.current!.validate(full),
            passApi.current!.validate(full),
            confPassApi.current!.validate(full),
            {valid: !full || termsError == null, error: !full ? null : termsError}
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, [termsError]);

    const onSignUp = useCallback(({
                                      authClient,
                                      setUser,
                                      setError,
                                      setView,
                                      setEmail,
                                      wantedPlan,
                                      wantedKind,
                                      wantedBilling,
                                      onComplete
                                  }: AuthActionContext) => {
        fetch('/api/auth/validate-email', {
            method: 'POST',
            body: JSON.stringify({email})
        }).then(r => {
            if (r.ok) {
                return r.json();
            }
            console.warn('Failed to validate email', r);
        }).then((data: { exists: boolean }) => {
            if (data.exists) {
                throw new Error('User with that email already exists. Please sign in instead. If you forgot your password you can reset it.');
            }
        }).then(() => {
            return authClient.auth.signUp({
                email,
                password,
                options: {
                    data: {
                        passwordCreated: true,
                        wantedPlan,
                        wantedKind,
                        wantedBilling,
                        affiliate,
                        consented
                    },
                    emailRedirectTo: APP_URL
                }
            });
        }).then(({data, error}) => {
            if (error) {
                throw error;
            }
            // GA-Start
            gaSignup(affiliate, wantedPlan, wantedKind, wantedBilling);
            // GA-End
            // try to sign up the affiliate via Tolt as a Lead
            try {
                let toltReferral: string | undefined = undefined;
                if (affiliate != null && !affiliate.isCustom) {
                    toltReferral = affiliate.affiliateId;
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (toltReferral) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
                    (window as any).tolt.signup(email);
                }
            } catch (e) {
                console.error(`Failed to sign up referral ${email} with Tolt`);
            }
            //
            let profileError: AuthenticationError | null = null;
            try {
                // create profile
                const userId = data.user!.id;
                fetch('/api/profile/create', {
                    method: 'POST',
                    body: JSON.stringify({
                        userId,
                        affiliate,
                        consented
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(r => {
                    if (!r.ok) {
                        throw new Error('Failed to create profile');
                    }
                }).catch(e => {
                    Sentry.captureException(e);
                    throw e;
                })
            } catch (e) {
                Sentry.captureException(e);
                const message = `Signed up successfully, but failed to create your profile, please contact ${SUPPORT_EMAIL}`;
                console.error(message, e);
                profileError = new AuthenticationError(message, e);
            }
            setError(profileError);
            setEmail(email);
            setUser(data.user);
            setView(AuthViews.SignUpSuccess);
        }).catch(e => {
            Sentry.captureException(e);
            console.error('Error signing up', e);
            setError(new AuthenticationError('Could not sign you up', e));
            setUser(null);
        }).finally(onComplete)
    }, [email, password, affiliate, consented, gaSignup]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onSignUp,
        extraContent: () => (
            <Text size={'sm'} c={'dimmed'}>If you already have an account, you can
                {' '}{viewLink(AuthViews.SignIn, 'sign in')}{' '}
                instead.
            </Text>
        )
    }));

    const theme = useMantineTheme();
    const redColor = theme.colors.rougeRed[5];
    const dimmed = useSlightlyDimmedColor();

    return (
        <Stack gap={'sm'}>
            {!IS_PAID_IN_PRODUCTION && (
                <Text size={'md'} c={dimmed} pb={'xs'}>
                    All exercises and features are <Accent>free</Accent>
                    {' '}during the {IS_ALPHA ? 'Alpha ' : IS_BETA ? 'Beta ' : ''}testing period.
                </Text>
            )}
            {IS_PAID_IN_PRODUCTION && (
                <Text size={'md'} c={dimmed} pb={'xs'}>
                    <Span>Enjoy a free 14-day trial with <Emp>access to all exercises and features</Emp>.</Span>
                </Text>
            )}
            <EmailControl ref={emailApi} value={email} onChange={onEmailChange} autoFocus={email?.length === 0}/>
            <PasswordControl ref={passApi} value={password} onChange={onPasswordChange} autoFocus={email?.length > 0}
                             placeholder={'Set your password'}
            />
            <PasswordControl ref={confPassApi} value={confPassword} onChange={onConfPasswordChange}
                             matchingPassword={password}
                             placeholder={'Confirm password'}/>
            <Stack gap={'xs'}>
                <Checkbox checked={agreed} onChange={e => setAgreed(e.target.checked)}
                          label={<Text size={'md'} c={dimmed}>I agree with the
                              {' '}<Anchor href={'/terms'} target={'_blank'}>Terms of service</Anchor>,
                              {' '}<Anchor href={'/privacy'} target={'_blank'}>Privacy policy</Anchor>,
                              {' '}and <Anchor href={'/cookies'} target={'_blank'}>Cookie policy</Anchor>.
                              <Text span c={'dimmed'} fz={'inherit'}>
                                  {' '}Please, read the photo-sensitive seizure <Anchor href={'#'}
                                                                                        onClick={onShowSeizureWarning}>
                                  warning</Anchor> before you continue.</Text>
                          </Text>} size={'md'}/>
                {(showTermsError && termsError != null) && (
                    <Group align={'center'} gap={4}>
                        <IconExclamationCircle color={redColor} size={16}/>
                        <Text size={'sm'} c={redColor}>{termsError}</Text>
                    </Group>
                )}
                <Checkbox checked={consented} onChange={e => setConsented(e.target.checked)}
                          label={
                              <Text size={'md'} c={dimmed}>I agree to get emails about cool stuff happening with
                                  FreeLimbs
                                  <Span c={'dimmed'}>{' '}(optional)</Span>
                              </Text>
                          }
                          size={'md'}/>
            </Stack>
            {showSeizureWarning && (
                <PhotoSensitivityWarning onClose={onSeizureWarningClosed}/>
            )}
        </Stack>
    );
});