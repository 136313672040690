"use client";

import {CSSProperties, ReactNode, Suspense, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AuthView, AuthViewActions, AuthViews, AuthViewTitles} from "@/app/(layout)/auth/[view]/AuthViews";
import {
    Anchor,
    Button,
    Card,
    Center,
    Notification, Stack,
    useMantineColorScheme
} from "@mantine/core";
import {MobiTitle} from "@/components/common/MobiTitle";
import {CoolCard} from "@/components/common/CoolCard";
import {useMobile, useTiltedMobile} from "@/components/common/hooks/responsive-utils";
import {SignInView} from "@/app/(layout)/auth/[view]/views/SignInView";
import {usePrimaryColor} from "@/components/common/hooks/useColors";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {useHotkeys} from "@mantine/hooks";
import {SignUpView} from "@/app/(layout)/auth/[view]/views/SignUpView";
import {MagicLinkView} from "@/app/(layout)/auth/[view]/views/MagicLinkView";
import detectIncognito from "detectincognitojs";
import {ForgotPasswordView} from "@/app/(layout)/auth/[view]/views/ForgotPasswordView";
import {SignUpSuccessView} from "@/app/(layout)/auth/[view]/views/SignUpSuccessView";
import {Para} from "@/components/common/Para";
import {UpdatePasswordView} from "@/app/(layout)/auth/[view]/views/UpdatePasswordView";
import {MagicLinkSentView} from "@/app/(layout)/auth/[view]/views/MagicLinkSentView";
import {PasswordLinkSentView} from "@/app/(layout)/auth/[view]/views/PasswordLinkSentView";
import {CreatePasswordView} from "@/app/(layout)/auth/[view]/views/CreatePasswordView";
import {createClient} from "@/utils/supabase/client";
import {User} from "@supabase/supabase-js";
import {useRouter} from "next/navigation";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import {useAffiliate} from "@/components/common/hooks/useAffiliate";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {SUPPORT_EMAIL} from "@/common/utils";
import ToltScript from "@/app/(layout)/pricing/ToltScript";
import TrackAffiliate from "@/components/common/TrackAffiliate";

export default function AuthPage(
    {
        params,
        searchParams,
    }: {
        params: { view: AuthView };
        searchParams: { [key: string]: string | string[] | undefined };
    }) {
    const {colorScheme: scheme} = useMantineColorScheme();

    const isMobile = useMobile();
    const isTilted = useTiltedMobile();
    const primaryColor = usePrimaryColor();

    const [showIncognitoWarning, setShowIncognitoWarning] = useState(false);
    useEffect(() => {
        detectIncognito()
            .then(result => {
                setShowIncognitoWarning(result.isPrivate);
            })
            .catch(e => {
                // couldn't figure out if incognito - ignore
            });
    }, []);

    const pageView = params.view;
    const [view, setView] = useState<AuthView>(pageView);
    useEffect(() => {
        setView(pageView);
    }, [pageView]);

    useEffect(() => {
        document.body.scroll(0, 0);
        document.title = `FreeLimbs | ${AuthViewTitles[view]}`;
    }, [view]);

    const searchEmail = typeof searchParams.email === 'string' ? searchParams.email : undefined;
    const [email, setEmail] = useState<string | undefined>(searchEmail);
    useEffect(() => {
        if (searchEmail) {
            setEmail(searchEmail);
        }
    }, [searchEmail]);

    const sectionBackground = useMemo(() => {
        return {
            backgroundImage: scheme === 'dark' ?
                "url('/images/backdrop-dark.png')" :
                "url('/images/backdrop-light.png')",
            backgroundSize: 'cover'
        } as CSSProperties;
    }, [scheme]);

    const viewLink = (view: AuthView, label: string) => (
        <Anchor title={label} href={''} onClick={(e) => {
            e.preventDefault();
            setView(view);
            return false;
        }}>{label}</Anchor>
    );

    const [user, setUser] = useState<User | null>(null);
    const [error, setError] = useState<AuthenticationError | null>(null);
    const supabase = useMemo(() => createClient(), []);
    const router = useRouter();
    const affiliate = useAffiliate();

    const viewApi = useRef<AuthViewApi | null>(null);
    const viewProps: AuthViewProps = useMemo(() => ({
        ref: viewApi,
        viewLink,
        user,
        email,
        affiliate
    }), [email, user, affiliate]);

    const currentView = useMemo(() => {
        switch (view) {
            case AuthViews.SignIn:
                return <SignInView {...viewProps} />;
            case AuthViews.SignUp:
                return <SignUpView {...viewProps} />;
            case AuthViews.SignUpSuccess:
                return <SignUpSuccessView {...viewProps} />;
            case AuthViews.CreatePassword:
                return <CreatePasswordView {...viewProps} />;
            case AuthViews.MagicLink:
                return <MagicLinkView {...viewProps} />;
            case AuthViews.MagicLinkSent:
                return <MagicLinkSentView {...viewProps} />;
            case AuthViews.ForgotPassword:
                return <ForgotPasswordView {...viewProps} />;
            case AuthViews.PasswordResetLinkSent:
                return <PasswordLinkSentView {...viewProps} />;
            case AuthViews.UpdatePassword:
                return <UpdatePasswordView {...viewProps} />;
            default:
                return null;
        }
    }, [view, viewProps]);

    const [extraContent, setExtraContent] = useState<ReactNode>(null);
    useEffect(() => {
        setExtraContent(viewApi.current?.extraContent() ?? null);
        setError(null);
    }, [view]);

    const onAction = useCallback(() => {
        const {valid} = viewApi.current!.validate(true);
        if (!valid) {
            return;
        }
        const api = viewApi.current!;
        if (api.isInformative?.() || !api.performAction) {
            return;
        }
        setSubmitting(true);
        api.performAction({
            authClient: supabase,
            router,
            wantedPlan: searchParams.plan as string | undefined,
            wantedKind: searchParams.kind as string | undefined,
            wantedBilling: searchParams.billing as string | undefined,
            redirectTo: typeof searchParams.redirectedFrom === 'string' ? searchParams.redirectedFrom : '/',
            setError,
            setUser,
            setView,
            setEmail,
            onComplete: () => {
                setSubmitting(false);
            }
        });
    }, [router, searchParams.billing, searchParams.kind, searchParams.plan, searchParams.redirectedFrom, supabase]);

    const width = useMemo(() => (isMobile && !isTilted) ? '100%' : 500, [isMobile, isTilted]);

    const [submitting, setSubmitting] = useState(false);
    useHotkeys([['enter', onAction]], [], true);

    useEffect(() => {
        const {data} = supabase.auth.onAuthStateChange((event, session) => {
            // set the provider token in local storage because signInWithOAuth causes a redirect,
            // you need to fetch the provider tokens from the callback.
            try {
                if (session && session.provider_token) {
                    window.localStorage.setItem('oauth_provider_token', session.provider_token)
                }
                if (session && session.provider_refresh_token) {
                    window.localStorage.setItem('oauth_provider_refresh_token', session.provider_refresh_token)
                }
            } catch (e) {
                console.error('Failed to set provider token in local storage (incognito?)', e);
            }

            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(async () => {
                if (event === "INITIAL_SESSION") {
                    if (session?.user != null) {
                        setUser(session?.user ?? null);
                        setError(null);
                    } else {
                        try {
                            const userData = await supabase.auth.getUser();
                            setUser(userData.data.user ?? null);
                        } catch (e) {
                            setError(new AuthenticationError('Failed to retrieve authenticated user', e));
                            setUser(null);
                        }
                    }
                } else if (event === "SIGNED_IN") {
                    // [supabase] This event can fire very frequently depending on the number of tabs open in your application.
                    setUser(session?.user ?? null)
                    setError(null);
                } else if (event === "SIGNED_OUT") {
                    try {
                        window.localStorage.removeItem('oauth_provider_token')
                        window.localStorage.removeItem('oauth_provider_refresh_token')
                    } catch (e) {
                        console.error('Failed to remove provider token from local storage (incognito?)', e);
                    }
                    setUser(null);
                    setError(null);
                    setView(AuthViews.SignIn);
                } else if (event === "PASSWORD_RECOVERY") {
                    setError(null);
                    setView(AuthViews.UpdatePassword);
                } else if (event === "USER_UPDATED") {
                    // TODO: handle password/avatar update
                    setUser(null);
                    setError(null);
                    setView(AuthViews.SignIn);
                }
            }, 0);
        });
        return () => {
            data.subscription.unsubscribe();
        };
    }, [supabase.auth]);

    return (
        <Center>
            <Stack gap={0}
                   align={'center'}
                   pt={isTilted ? 0 : 'xs'}
                   pb={0}
                   w={'100%'}>
                <CoolCard radius={'md'} miw={width} maw={width}>
                    <Card.Section px={'xs'}>
                        <Stack pt={isTilted ? 6 : 'xs'} pb={isTilted ? 6 : 12}>
                            <MobiTitle pt={'md'} ta={'center'} order={isTilted ? 2 : 1}>{AuthViewTitles[view]}</MobiTitle>
                        </Stack>
                    </Card.Section>
                    {showIncognitoWarning && (
                        <Card.Section withBorder px={'xs'}>
                            <Stack px={'md'} w={'100%'} py={'xs'}>
                                <Notification // withBorder
                                    withCloseButton
                                    onClose={() => setShowIncognitoWarning(false)}
                                    bg={'var(--mantine-color-body)'}
                                    color={primaryColor}
                                    title={'Private browsing warning'}>
                                    <Para pt={'xs'}>
                                        It looks like you are using private browsing/incognito mode. This may cause
                                        issues with signing
                                        in or signing up. Please reopen the page in a regular browser window.
                                    </Para>
                                </Notification>
                            </Stack>
                        </Card.Section>
                    )}
                    {error != null && (
                        <Card.Section withBorder px={'xs'}>
                            <Stack px={'md'} w={'100%'} py={'xs'}>
                                <Notification
                                    withBorder
                                    withCloseButton={false}
                                    bg={'var(--mantine-color-body)'}
                                    color={'red'}
                                    title={error.message}>
                                    <Stack gap={'xs'}>
                                        <Para pt={'xs'}>
                                            {' '}If the error persists, please email
                                            {' '}<Anchor fz={'inherit'} href={`mailto:${SUPPORT_EMAIL}`}
                                                         target={'_blank'}>{SUPPORT_EMAIL}</Anchor>.
                                        </Para>
                                        <Para>
                                            Technical
                                            details: {error.inner.message}{error.code ? ` (${error.code})` : ''}
                                        </Para>
                                    </Stack>
                                </Notification>
                            </Stack>
                        </Card.Section>
                    )}
                    <Card.Section withBorder px={'xs'}>
                        <Stack pt={4} pb={'md'}>
                            <Stack gap={'sm'} px={'md'} w={'100%'}>
                                <form autoComplete={'on'}>
                                    {currentView}
                                </form>
                            </Stack>
                        </Stack>
                    </Card.Section>
                    {AuthViewActions[view] && !viewApi.current?.isInformative?.() && (
                        <Card.Section style={sectionBackground} withBorder>
                            <Stack py={isTilted ? 8 : 'xs'}>
                                <Center>
                                    <Button px={'xl'}
                                            variant={'filled'}
                                            size={isTilted ? 'sm' : 'md'}
                                            loading={submitting}
                                            loaderProps={{type: 'dots'}}
                                            onClick={onAction}>{AuthViewActions[view]}</Button>
                                </Center>
                            </Stack>
                        </Card.Section>
                    )}
                    {extraContent && (
                        <Card.Section withBorder px={'xs'}>
                            <Stack py={'sm'}>
                                <Stack gap={'sm'} px={'md'} w={'100%'}>
                                    {extraContent}
                                </Stack>
                            </Stack>
                        </Card.Section>
                    )}
                </CoolCard>
            </Stack>
            {/* affiliates BEGIN */}
            <ToltScript />
            <Suspense fallback={null}>
                <TrackAffiliate/>
            </Suspense>
            {/* affiliates END */}
        </Center>
    );
}
