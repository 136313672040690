"use client";

import {useHighContrastColor} from "@/components/common/hooks/useColors";
import {Group, Stack, Text, TextInput, Tooltip, useMantineTheme} from "@mantine/core";
import {ChangeEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react";
import cx from "@/components/common/MantineCard.module.css";
import {IconAsterisk, IconCheck, IconExclamationCircle, IconMail} from "@tabler/icons-react";

export type EmailControlMethods = {
    validate: (validateEmpty?: boolean) => { valid: boolean, error: string | null };
}

export type EmailControlProps = {
    ref?: Ref<EmailControlMethods>;
    value: string;
    onChange: (value: string) => void;
    autoFocus?: boolean;
};

export const EmailControl = forwardRef((
    {
        value,
        onChange,
        autoFocus
    }: EmailControlProps, ref: Ref<EmailControlMethods>) => {

    const theme = useMantineTheme();
    const redColor = theme.colors.rougeRed[5];
    const contrastColor = useHighContrastColor();

    const validationError = useMemo(() => {
        const errorMessage = 'Email looks invalid, please double-check it';
        const input = value.trim();
        if (input.length === 0) {
            return null;
        }
        const [user, domain] = input.split('@').map(s => s.trim());
        if (!user) {
            return errorMessage;
        }
        const [domainName, tld] = (domain ?? '').split('.').map(s => s.trim());
        if (!domainName || !tld || tld.length < 2) {
            return errorMessage;
        }
        return null;
    }, [value]);

    const onValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    }, [onChange]);

    const [error, setError] = useState(validationError);
    useEffect(() => {
        setError(validationError);
    }, [validationError]);

    const validate = useCallback((validateEmpty?: boolean) => {
        let error = validationError;
        if (error == null && validateEmpty && value.length === 0) {
            error = 'Email is required';
        }
        setError(error);
        return {
            valid: error == null,
            error
        };
    }, [validationError, value.length]);

    useImperativeHandle(ref, () => ({
        validate
    }));

    return (
        <Stack gap={'xs'}>
            <TextInput
                classNames={{
                    input: cx.root
                }}
                size={'md'}
                value={value}
                type={'email'}
                autoComplete={'on'}
                autoFocus={autoFocus}
                leftSection={<IconMail size={18}/>}
                placeholder={'Enter your email'}
                onChange={onValueChange}
                rightSection={
                    (value.length === 0) ? (
                            <Tooltip label={'Email is required'} openDelay={350} closeDelay={100}>
                                <IconAsterisk color={redColor} size={12}/>
                            </Tooltip>
                        ) :
                        validationError ? (
                            <IconExclamationCircle color={redColor} size={16}/>
                        ) : (
                            <IconCheck color={contrastColor} strokeWidth={2.5} size={16}/>
                        )
                }
            />
            {(error != null) && (
                <Group align={'center'} gap={4}>
                    <IconExclamationCircle color={redColor} size={16}/>
                    <Text size={'sm'} c={redColor}>{error}</Text>
                </Group>
            )}
        </Stack>
    )
});
