import {
    Button, Card, Center, Divider,
    Group,
    List, MantineStyleProp, Modal,
    Space,
    Text,
    Title
} from "@mantine/core";
import cx from '@/components/common/MantineCard.module.css';
import {useDisclosure} from "@mantine/hooks";
import {Fragment, useCallback} from "react";
import {useMobile} from "@/components/common/hooks/responsive-utils";

export function PhotoSensitivityWarning({onClose}: { onClose?: () => void }) {
    const isMobile = useMobile();
    const isShort = useMobile();

    const [opened, {close}] = useDisclosure(true);
    const handleOnClose = useCallback(() => {
        onClose?.();
        close();
    }, [close, onClose]);

    const justified = {textAlign: 'justify', textJustify: 'inter-character'} as MantineStyleProp;
    const Container = isMobile ? Fragment : Center;

    return (
        <Modal opened={opened} withCloseButton={false} onClose={handleOnClose}
               fullScreen
               closeOnEscape={false}
               closeOnClickOutside={false}>
            <Container>
                <Card classNames={cx} shadow={isMobile ? 'none' : 'xl'} py={isMobile ? 0 : 'lg'}
                      pb={isMobile ? 'md' : 'lg'} px={isMobile ? 'md' : 'xl'} maw={isMobile ? undefined : 1024}
                      withBorder={false}>
                    <Center>
                        <Title order={isMobile ? 4 : 2}>Photosensitive Seizure Warning</Title>
                    </Center>
                    <Divider my={'xs'}/>
                    <Text style={justified} size={isMobile ? 'sm' : 'md'}>
                        A very small percentage of people may experience a seizure when exposed to certain
                        visual
                        images,
                        including flashing lights or patterns. Even people who have no history of seizures or
                        epilepsy
                        may have an undiagnosed condition that can cause these &quot;photosensitive epileptic
                        seizures&quot;
                        while using the app.
                    </Text>
                    <Space h={'sm'}/>
                    <Text style={justified} size={(isMobile || isShort) ? 'sm' : 'lg'} fw={'bold'}>
                        Immediately stop using the app and consult a doctor if you experience any
                        symptoms.</Text>
                    <Space h={'sm'}/>
                    <Text style={justified} size={(isMobile || isShort) ? 'xs' : 'md'}>
                        These seizures may have a variety of symptoms, including lightheadedness, altered
                        vision,
                        eye or face twitching, jerking or shaking of arms or legs, disorientation, confusion,
                        or momentary loss of awareness. Seizures may also cause loss of consciousness or
                        convulsions
                        that can lead to injury from falling down or striking nearby objects.
                    </Text>
                    <Space h={'sm'}/>
                    <Text style={justified} size={(isMobile || isShort) ? 'xs' : 'md'}>
                        Parents should watch for or ask their children about the above symptoms.
                        Children and teenagers are more likely than adults to experience these seizures.
                    </Text>
                    <Space h={'sm'}/>
                    <Text style={justified} size={(isMobile || isShort) ? 'xs' : 'md'}>
                        You may reduce risk of photosensitive epileptic seizures by taking the following
                        precautions:
                        <List fz={'inherit'}>
                            <List.Item>Use the app in a well-lit room.</List.Item>
                            <List.Item>Do not use the app when you are drowsy or fatigued.</List.Item>
                        </List>
                    </Text>
                    <Space h={'sm'}/>
                    <Text style={justified} size={(isMobile || isShort) ? 'xs' : 'md'}>
                        If you or any of your relatives have a history of seizures or epilepsy, consult a doctor
                        before
                        using the app.
                    </Text>
                    <Divider my={'xs'}/>
                    <Group gap={0} align={'center'} justify={'center'} mt={'sm'}>
                        <Button variant={'filled'} size={(isMobile || isShort) ? 'sm' : 'md'}
                                onClick={() => onClose?.()}>
                            I understand
                        </Button>
                    </Group>
                </Card>
            </Container>
        </Modal>
    );
}
