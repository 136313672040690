"use client";

import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from "react";
import {PasswordControl, PasswordControlMethods} from "@/components/common/PasswordControl";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Stack, Text} from "@mantine/core";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import * as Sentry from "@sentry/nextjs";

export const UpdatePasswordView = forwardRef(({viewLink}: AuthViewProps, ref: Ref<AuthViewApi>) => {
    const [password, setPassword] = useState('');
    const passApi = useRef<PasswordControlMethods | null>(null);
    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const [confPassword, setConfPassword] = useState('');
    const confPassApi = useRef<PasswordControlMethods | null>(null);
    const onConfPasswordChange = useCallback((value: string) => {
        setConfPassword(value);
    }, []);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        const errors = [
            passApi.current!.validate(full),
            confPassApi.current!.validate(full),
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, []);

    const onUpdatePassword = useCallback(({ authClient, setUser, setError, router, redirectTo }: AuthActionContext) => {
        authClient.auth.updateUser({
            password
        }).then(({data, error}) => {
            if (error) {
                throw error;
            }
            setUser(data.user);
            router.replace(redirectTo ?? '/');
        }).catch(e => {
            Sentry.captureException(e);
            console.error('Error updating password', e);
            setError(new AuthenticationError('Could not update your password', e));
        })
    }, [password]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onUpdatePassword,
        extraContent: () => (
            <Text size={'sm'} c={'dimmed'}>Recalled your password?
                {' '}{viewLink(AuthViews.SignIn, 'Sign in')}{' '} instead.
            </Text>
        )
    }));

    return (
        <Stack gap={'sm'}>
            <PasswordControl ref={passApi} value={password} onChange={onPasswordChange}
                             placeholder={'New password'}
            />
            <PasswordControl ref={confPassApi} value={confPassword} onChange={onConfPasswordChange}
                             matchingPassword={password}
                             placeholder={'Confirm password'}/>
        </Stack>
    );
});