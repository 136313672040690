"use client";

import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from "react";
import {PasswordControl, PasswordControlMethods} from "@/components/common/PasswordControl";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Notification, Stack} from "@mantine/core";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {Para} from "@/components/common/Para";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import * as Sentry from "@sentry/nextjs";

export const CreatePasswordView = forwardRef(({viewLink}: AuthViewProps, ref: Ref<AuthViewApi>) => {
    const [password, setPassword] = useState('');
    const passApi = useRef<PasswordControlMethods | null>(null);
    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const [confPassword, setConfPassword] = useState('');
    const confPassApi = useRef<PasswordControlMethods | null>(null);
    const onConfPasswordChange = useCallback((value: string) => {
        setConfPassword(value);
    }, []);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        const errors = [
            passApi.current!.validate(full),
            confPassApi.current!.validate(full),
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, []);

    const dimmed = useSlightlyDimmedColor();

    const onCreatePassword = useCallback(({authClient, setUser, setError, router, redirectTo}: AuthActionContext) => {
        authClient.auth.updateUser({
            password
        }).then(({data, error}) => {
            if (error) {
                throw error;
            }
            setUser(data.user);
            router.replace(redirectTo ?? '/');
        }).catch(e => {
            Sentry.captureException(e);
            console.error('Error updating password', e);
            setError(new AuthenticationError('Could not create your password', e));
        })
    }, [password]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onCreatePassword,
        extraContent: () => null
    }));

    return (
        <Stack gap={'sm'}>
            <Notification
                withCloseButton={false}
                bg={'var(--mantine-color-body)'}
                color={'green'}
                title={'You account has been successfully activated'}>
                <Stack gap={'xs'} pt={'xs'}>
                    <Para c={dimmed} fz={'md'}>
                        You now need to create a password so you can sign in going forward.
                        The password must be at least 6 characters long.
                    </Para>
                </Stack>
            </Notification>
            <PasswordControl ref={passApi} value={password} onChange={onPasswordChange}
                             placeholder={'New password'}
            />
            <PasswordControl ref={confPassApi} value={confPassword} onChange={onConfPasswordChange}
                             matchingPassword={password}
                             placeholder={'Confirm password'}/>
        </Stack>
    );
});