import {AuthError} from "@supabase/supabase-js";

export class AuthenticationError extends Error {
    public readonly inner: Error;
    public readonly code: string | undefined = undefined;

    constructor(message: string, inner: any) {
        super(message);
        this.inner = inner instanceof Error ? inner : new Error(String(inner));
        if (this.inner instanceof AuthError) {
            this.code = this.inner.code ? String(this.inner.code) : undefined;
        }
    }
}