"use client";

import {EmailControl, EmailControlMethods} from "@/components/common/EmailControl";
import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from "react";
import {PasswordControl, PasswordControlMethods} from "@/components/common/PasswordControl";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Stack, Text} from "@mantine/core";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import * as Sentry from "@sentry/nextjs";

export const SignInView = forwardRef(({viewLink}: AuthViewProps, ref: Ref<AuthViewApi>) => {
    const [email, setEmail] = useState('');
    const emailApi = useRef<EmailControlMethods | null>(null);
    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const [password, setPassword] = useState('');
    const passApi = useRef<PasswordControlMethods | null>(null);
    const onPasswordChange = useCallback((value: string) => {
        setPassword(value);
    }, []);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        const errors = [
            emailApi.current!.validate(full),
            passApi.current!.validate(full)
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, []);

    const onSignIn = useCallback(({authClient, setUser, setError, router, redirectTo, onComplete}: AuthActionContext) => {
        authClient.auth.signInWithPassword({
            email,
            password
        }).then(({data, error}) => {
            if (error) {
                throw error;
            }
            router.replace(redirectTo);
        }).catch(e => {
            Sentry.captureException(e);
            console.error('Error signing in', e);
            setError(new AuthenticationError('Could not sign you in', e));
            setUser(null);
        }).finally(onComplete)
    }, [email, password]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onSignIn,
        extraContent: () => (
            <>
                <Text size={'sm'} c={'dimmed'}>Forgot your password?
                    {' '}You can {viewLink(AuthViews.ForgotPassword, 'reset it')},
                    or sign in by requesting a {viewLink(AuthViews.MagicLink, 'magic link')}
                    {' '}sent to your email.
                </Text>
                <Text size={'sm'} c={'dimmed'}>If you don&apos;t have an account, you can
                    {' '}{viewLink(AuthViews.SignUp, 'sign up')} for one.
                    {' '}It&apos;s free, and doesn&apos;t require any payment details.
                </Text>
            </>
        )
    }));

    return (
        <Stack gap={'sm'}>
            <EmailControl ref={emailApi} value={email} onChange={onEmailChange} autoFocus />
            <PasswordControl ref={passApi} value={password} onChange={onPasswordChange}/>
        </Stack>
    );
});