"use client";

import {EmailControl, EmailControlMethods} from "@/components/common/EmailControl";
import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from "react";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Stack, Text} from "@mantine/core";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import * as Sentry from "@sentry/nextjs";

export const ForgotPasswordView = forwardRef(({viewLink}: AuthViewProps, ref: Ref<AuthViewApi>) => {
    const [email, setEmail] = useState('');
    const emailApi = useRef<EmailControlMethods | null>(null);
    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        const errors = [
            emailApi.current!.validate(full)
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, []);

    const onRequestPasswordReset = useCallback(({ authClient, setError, setView, setEmail }: AuthActionContext) => {
        authClient.auth.resetPasswordForEmail(email)
            .then(({data, error}) => {
                if (error) {
                    throw error;
                }
                setError(null);
                setEmail(email);
                setView(AuthViews.PasswordResetLinkSent);
            })
            .catch(e => {
                Sentry.captureException(e);
                console.error('Error requesting password reset', e);
                setError(new AuthenticationError('Could not send a password reset link', e));
            });
    }, [email]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onRequestPasswordReset,
        extraContent: () => (
            <>
                <Text size={'sm'} c={'dimmed'}>If you don&apos;t want to reset your password right now, you can
                    {' '}sign in by requesting a {viewLink(AuthViews.MagicLink, 'magic link')}
                    {' '}sent to your email.
                </Text>
                <Text size={'sm'} c={'dimmed'}>Recalled your password?
                    {' '}{viewLink(AuthViews.SignIn, 'Sign in')}{' '}
                    instead.
                </Text>
            </>
        )
    }));

    return (
        <Stack gap={'sm'}>
            <Text size={'md'}>Don&apos;t worry, happens to all of us. Enter your email address, and you&apos;ll
                get a link to change your password.</Text>
            <EmailControl ref={emailApi} value={email} onChange={onEmailChange} autoFocus />
        </Stack>
    );
});