import {
    DEBUG_AFFILIATES
} from "@/components/common/affiliate-utils";
import {ResolvedAffiliate} from "@/app/lib/pricing/types";
import {useProfile} from "@/components/common/hooks/useProfile";
import {useLocalBrowserAffiliate} from "@/components/common/hooks/useLocalBrowserAffiliate";

const DEBUG = DEBUG_AFFILIATES;

export function useAffiliate(): ResolvedAffiliate | undefined {
    const localBrowserAffiliate = useLocalBrowserAffiliate();
    const { user, profile } = useProfile();

    // We don't have the usual flow of a customer checking out directly from an affiliate link
    //      1. Customer clicks on an affiliate link
    //      2. Customer signs up (and we store affiliate in their profile), starts trial
    //      3. Two weeks later, customer converts to a paid plan
    //      4. We check the stored affiliate in the profile, then anything else we stored or have now in the browser

    // check recorded one in the profile
    if (profile?.affiliate != null) {
        DEBUG && console.log('Resolved affiliate from profile', profile?.affiliate);
        return profile.affiliate;
    }

    // if we couldn't save it in the profile, or profile hasn't loaded yet, try from the user's metadata
    //      it's the same one we save in the profile, but we want it as soon as possible
    const userAffiliate = user?.user_metadata?.affiliate as ResolvedAffiliate | undefined | null;
    if (userAffiliate != null) {
        DEBUG && console.log('Resolved affiliate from user metadata', userAffiliate);
        return userAffiliate;
    }

    if (localBrowserAffiliate != null) {
        DEBUG && console.log('Resolved affiliate from browser', localBrowserAffiliate);
        return localBrowserAffiliate;
    }

    return undefined;
}
