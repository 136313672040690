"use client";

import {forwardRef, Ref, useImperativeHandle} from "react";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Notification, Stack, Text} from "@mantine/core";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {Para} from "@/components/common/Para";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {Span} from "@/components/common/Span";

export const PasswordLinkSentView = forwardRef((
    {
        viewLink,
        user,
        email
    }: AuthViewProps, ref: Ref<AuthViewApi>) => {

    const dimmed = useSlightlyDimmedColor();

    useImperativeHandle(ref, () => ({
        validate: (full?: boolean | undefined) => ({valid: true, errors: []}),
        extraContent: () => (
            <>
                <Text size={'sm'}>
                    If you don&apos;t see the email, please check your spam folder.
                    If you think you&apos;ve waited long enough, you
                    {' '}can {viewLink(AuthViews.ForgotPassword, 'request a new password reset link')}.
                </Text>
                <Text size={'sm'} c={'dimmed'}>Recalled your password?
                    {' '}{viewLink(AuthViews.SignIn, 'Sign in')}{' '} instead.
                </Text>
            </>
        ),
        isInformative: () => true
    }));

    return (
        <Notification
            withCloseButton={false}
            bg={'var(--mantine-color-body)'}
            color={'green'}
            title={'Please check your email'}>
            <Stack gap={'xs'} pt={'xs'}>
                <Para c={dimmed} fz={'md'}>
                    A password reset link was sent to your email address
                    {' '}<Span fz={'inherit'} span fw={'bold'}>{user?.email ?? email}</Span>.
                    Please check your inbox and click the link to reset your password.
                </Para>
            </Stack>
        </Notification>
    );
});