"use client";

import {forwardRef, Ref, useEffect, useImperativeHandle} from "react";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Anchor, Divider, Notification, Stack, Text} from "@mantine/core";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {APP_URL} from "@/common/utils";
import {Para} from "@/components/common/Para";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {Emp} from "@/components/common/Emp";
import {useRouter} from "next/navigation";
import {Span} from "@/components/common/Span";

export const SignUpSuccessView = forwardRef((
    {
        user,
        email
    }: AuthViewProps, ref: Ref<AuthViewApi>) => {

    const dimmed = useSlightlyDimmedColor();

    useImperativeHandle(ref, () => ({
        validate: (full?: boolean | undefined) => ({valid: true, errors: []}),
        extraContent: () => null,
        isInformative: () => true
    }));

    const router = useRouter();

    useEffect(() => {
        if (user?.email_confirmed_at != null) {
            setTimeout(() => router.push(APP_URL), 2000);
        }
    }, [router, user?.email_confirmed_at]);

    return (
        <Stack gap={'sm'}>
            <Text size={'lg'}>
                We are <Emp>super excited</Emp> to have you on board and watch you <Emp>grow</Emp>!
            </Text>
            {(!user?.email_confirmed_at) && (
                <>
                    <Stack gap={'sm'}>
                        <Notification
                            withCloseButton={false}
                            bg={'var(--mantine-color-body)'}
                            color={'green'}
                            title={'Please check your email'}>
                            <Stack gap={'xs'} pt={'xs'}>
                                <Para c={dimmed} fz={'md'}>
                                    A verification link was sent to your email address
                                    {' '}<Span fz={'inherit'} span fw={'bold'}>{user?.email ?? email}</Span>.
                                </Para>
                                <Para c={dimmed} fz={'md'}>
                                    You <b>must</b> verify your email address to be able to sign in, and use the app.
                                </Para>
                                <Para c={dimmed} fz={'md'}>
                                    Please check your inbox and click the confirmation link to complete your
                                    registration (check your spam folder too).
                                </Para>
                            </Stack>
                        </Notification>
                    </Stack>
                    <Divider/>
                    <Text size={'sm'}>
                        If you have already verified your email address, you can
                        {' '}<Anchor title={'start using the app'} href={APP_URL}>start using the app</Anchor>.
                        {' '}You might be asked to sign in again.
                    </Text>
                </>
            )}
            {(user?.email_confirmed_at) && (
                <>
                    <Stack gap={'sm'}>
                        <Text size={'md'}>
                            Thank you for confirming your email address. You can now
                            {' '}<Anchor title={'start using the app'} href={APP_URL}>start using the app</Anchor>.
                        </Text>
                    </Stack>
                </>
            )}
        </Stack>
    );
});