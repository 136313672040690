"use client";

import {Title, TitleOrder, TitleProps} from "@mantine/core";
import {useMobile, useShortScreen} from "@/components/common/hooks/responsive-utils";

export function MobiTitle(props: TitleProps) {
    const isMobile = useMobile();
    const isShort = useShortScreen();

    const { order: originalOrder, ...rest } = props;
    const order: TitleOrder = ((isMobile || isShort) ? (originalOrder ?? 1) + 0 : (originalOrder ?? 1)) as TitleOrder;

    return <Title {...rest} order={order} />;
}
