export const AuthViews = {
    SignIn: 'sign-in',
    SignUp: 'sign-up',
    MagicLink: 'magic-link',
    MagicLinkSent: 'magic-link-sent',
    PasswordResetLinkSent: 'password-link-sent',
    ForgotPassword: 'forgot-password',
    CreatePassword: 'create-password',
    UpdatePassword: 'update-password',
    SignUpSuccess: 'signup-success',
} as const;

export type AuthView = typeof AuthViews[keyof typeof AuthViews];

export const AuthViewTitles: Record<AuthView, string> = {
    [AuthViews.SignIn]: 'Sign in',
    [AuthViews.SignUp]: 'Sign up',
    [AuthViews.MagicLink]: 'Sign in with magic link',
    [AuthViews.MagicLinkSent]: 'Magic link sent',
    [AuthViews.PasswordResetLinkSent]: 'Password reset link sent',
    [AuthViews.ForgotPassword]: 'Forgot password?',
    [AuthViews.CreatePassword]: 'Create your password',
    [AuthViews.UpdatePassword]: 'Set your new password',
    [AuthViews.SignUpSuccess]: 'Welcome to FreeLimbs!',
};

export const AuthViewActions: Record<AuthView, string> = {
    [AuthViews.SignIn]: 'Sign in',
    [AuthViews.SignUp]: 'Sign up',
    [AuthViews.MagicLink]: 'Get magic link',
    [AuthViews.ForgotPassword]: 'Reset password',
    [AuthViews.CreatePassword]: 'Create password',
    [AuthViews.UpdatePassword]: 'Update password'
} as Record<AuthView, string>;
