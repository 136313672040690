"use client";

import {useHighContrastColor} from "@/components/common/hooks/useColors";
import {Group, Stack, Text, TextInput, Tooltip, useMantineTheme} from "@mantine/core";
import {ChangeEvent, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState} from "react";
import cx from "@/components/common/MantineCard.module.css";
import {IconAsterisk, IconCheck, IconExclamationCircle, IconLock} from "@tabler/icons-react";

export type PasswordControlMethods = {
    validate: (validateEmpty?: boolean) => { valid: boolean, error: string | null };
}

export type PasswordControlProps = {
    ref?: Ref<PasswordControlMethods>;
    placeholder?: string;
    matchingPassword?: string | undefined;
    value: string;
    onChange: (value: string) => void;
    autoFocus?: boolean;
};

export const PasswordControl = forwardRef((
    {
        value,
        onChange,
        placeholder,
        matchingPassword,
        autoFocus
    }: PasswordControlProps, ref: Ref<PasswordControlMethods>) => {

    const theme = useMantineTheme();
    const redColor = theme.colors.rougeRed[5];
    const contrastColor = useHighContrastColor();

    const validationError = useMemo(() => {
        const input = value.trim();
        if (input.length === 0) {
            return null;
        }
        if (input.length < 6) {
            return 'Password must be at least 6 characters';
        }
        if (matchingPassword != null && input !== matchingPassword) {
            return 'Passwords do not match';
        }
        return null;
    }, [matchingPassword, value]);

    const onValueChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value);
    }, [onChange]);

    const [error, setError] = useState(validationError);
    useEffect(() => {
        setError(validationError);
    }, [validationError]);

    const validate = useCallback((validateEmpty?: boolean) => {
        let error: string | null = null;
        if (validationError) {
            error = validationError;
        } else if (validateEmpty && value.length === 0) {
            error = 'Password is required';
        }
        setError(error);
        return {
            valid: error == null,
            error
        };
    }, [validationError, value]);

    useImperativeHandle(ref, () => ({
        validate
    }));

    return (
        <Stack gap={'xs'}>
            <TextInput
                classNames={{
                    input: cx.root
                }}
                size={'md'}
                value={value}
                type={'password'}
                autoComplete={'on'}
                autoFocus={autoFocus}
                leftSection={<IconLock size={18}/>}
                placeholder={placeholder ?? 'Enter your password'}
                onChange={onValueChange}
                rightSection={
                    (value.length === 0) ? (
                            <Tooltip label={'Password is required'} openDelay={350} closeDelay={100}>
                                <IconAsterisk color={redColor} size={12}/>
                            </Tooltip>
                        ) :
                        validationError ? (
                            <IconExclamationCircle color={redColor} size={16}/>
                        ) : (
                            <IconCheck color={contrastColor} strokeWidth={2.5} size={16}/>
                        )
                }
            />
            {(error != null) && (
                <Group align={'center'} gap={4}>
                    <IconExclamationCircle color={redColor} size={16}/>
                    <Text size={'sm'} c={redColor}>{error}</Text>
                </Group>
            )}
        </Stack>
    )
});
