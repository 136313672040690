"use client";

import {EmailControl, EmailControlMethods} from "@/components/common/EmailControl";
import {forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from "react";
import {AuthViewApi} from "@/app/(layout)/auth/[view]/AuthViewApi";
import {Stack, Text} from "@mantine/core";
import {AuthViews} from "@/app/(layout)/auth/[view]/AuthViews";
import {AuthViewProps} from "@/app/(layout)/auth/[view]/AuthViewProps";
import {Span} from "@/components/common/Span";
import {AuthActionContext} from "@/app/(layout)/auth/[view]/AuthActionContext";
import {AuthenticationError} from "@/app/(layout)/auth/[view]/AuthenticationError";
import {APP_URL} from "@/common/utils";
import * as Sentry from "@sentry/nextjs";

export const MagicLinkView = forwardRef(({viewLink}: AuthViewProps, ref: Ref<AuthViewApi>) => {
    const [email, setEmail] = useState('');
    const emailApi = useRef<EmailControlMethods | null>(null);
    const onEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const validate = useCallback((full?: boolean | undefined): { valid: boolean, errors: string[] } => {
        const errors = [
            emailApi.current!.validate(full)
        ].filter(e => !e.valid).map(e => e.error) as string[];
        return {
            valid: errors.length === 0,
            errors
        };
    }, []);

    const onRequestMagicLink = useCallback(({ authClient, setError, setView, setEmail }: AuthActionContext) => {
        authClient.auth.signInWithOtp({
            email,
            options: {
                shouldCreateUser: false,
                emailRedirectTo: APP_URL
            }
        }).then(({data, error}) => {
            if (error) {
                throw error;
            }
            setError(null);
            setEmail(email);
            setView(AuthViews.MagicLinkSent);
        }).catch(e => {
            Sentry.captureException(e);
            console.error('Error requesting magic link', e);
            setError(new AuthenticationError('Could not send a magic link', e));
        });
    }, [email]);

    useImperativeHandle(ref, () => ({
        validate,
        performAction: onRequestMagicLink,
        extraContent: () => (
            <Text size={'sm'} c={'dimmed'}>Recalled your password?
                {' '}{viewLink(AuthViews.SignIn, 'Sign in')} instead.
            </Text>
        )
    }));

    return (
        <Stack gap={'sm'}>
            <Span>
                Enter your email address, click the button, and you will receive an email with a instant sign-in link.
                Click that link to sign in directly, without a password.
            </Span>
            <EmailControl ref={emailApi} value={email} onChange={onEmailChange} autoFocus />
        </Stack>
    );
});