"use client";

import {useCallback} from "react";
import {ResolvedAffiliate} from "@/app/lib/pricing/types";

export function useKeyEvents() {
    const tag = useCallback((event: string, data?: Record<string, unknown> | undefined) => {
        // TODO: figure out how to use Google Analytics
/*
        try {
            if (typeof window === 'undefined') {
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const gtag = (window as any).gtag as (tag: string, event: string, data?: Record<string, unknown> | undefined) => void;
            if (gtag == null) {
                return;
            }
            const tagData = data ?? {};
            gtag('event', event, tagData);
        } catch {
            // ignore - best effort to capture analytics
        }
*/
    }, []);

    const signup = useCallback((
        affiliate?: ResolvedAffiliate | undefined,
        type?: string | undefined,
        kind?: string | undefined,
        billing?: string | undefined
    ) => {
        tag('fl_signup', {
            method: affiliate != null ? (affiliate.isCustom ? 'custom' : 'tolt') : 'direct',
            wantedPlan: type ?? '',
            wantedKind: kind ?? '',
            wantedBilling: billing ?? '',
        });
    }, [tag]);

    const checkout = useCallback((priceId: string, affiliate: ResolvedAffiliate | undefined) => {
        tag('fl_begin_checkout', {
            priceId,
            method: affiliate != null ? (affiliate.isCustom ? 'custom' : 'tolt') : 'direct',
        });
    }, [tag]);

    const abandon = useCallback((priceId: string, affiliate: ResolvedAffiliate | undefined) => {
        tag('fl_abandon_checkout', {
            priceId,
            method: affiliate != null ? (affiliate.isCustom ? 'custom' : 'tolt') : 'direct',
        });
    }, [tag]);

    const purchase = useCallback((priceId: string, affiliate: ResolvedAffiliate | undefined) => {
        tag('fl_purchase', {
            priceId,
            method: affiliate != null ? (affiliate.isCustom ? 'custom' : 'tolt') : 'direct',
        });
    }, [tag]);

    return {
        gaSignup: signup,
        gaCheckout: checkout,
        gaAbandon: abandon,
        gaPurchase: purchase,
    }
}
